<template>
  <div v-if="employeesSalaryData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th rowspan="2">#</th>
            <th colspan="4">{{ $t("Employee.modelName") }}</th>
            <th rowspan="2">{{ $t("SalaryInquiries.totalSalary") }}</th>
            <th rowspan="2">{{ $t("SalaryInquiries.totalPaid") }}</th>
            <th rowspan="2">{{ $t("SalaryInquiries.totalRemaining") }}</th>
            <th rowspan="2">{{ $t("EmployeesEnquirySalariesPaids.paid") }}</th>
            <th rowspan="2">
              {{ $t("EmployeesEnquirySalariesPaids.modelName") }}
            </th>
            <th rowspan="2">{{ $t("SalaryInquiries.workTimesDetails") }}</th>
            <th rowspan="2">{{ $t("cancel") }}</th>
          </tr>
          <tr>
            <th>{{ $t("Employee.code") }}</th>
            <th class="cell-lg">{{ $t("Employee.name") }}</th>
            <th>{{ $t("Employee.phone") }}</th>
            <th>{{ $t("Employee.jobInContract") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(SalaryInquiry, index) in employeesSalaryData"
            :key="index"
          >
            <td>{{ ++index + pagination.currentIndex }}</td>
            <td>{{ isDataExist(SalaryInquiry.employeeCode) }}</td>
            <td class="cell-with-image">
              <img
                class="item-img-table"
                :src="BASE_URL + SalaryInquiry.employeeImagePath"
                :onerror="`this.src='${DEFAULT_IMG}'`"
              />
              <span>{{ isDataExist(SalaryInquiry.employeeNameCurrent) }}</span>
            </td>
            <td class="table-phone-number">
              {{ isDataExist(SalaryInquiry.employeePhoneWithCC) }}
            </td>
            <td>{{ isDataExist(SalaryInquiry.employeeJobNameCurrent) }}</td>
            <td>{{ moneyFormat(SalaryInquiry.totalSalary) }}</td>
            <td>{{ moneyFormat(SalaryInquiry.totalPaid) }}</td>
            <td>{{ moneyFormat(SalaryInquiry.totalRemaining) }}</td>

            <td>
              <!-- :disabled="SalaryInquiry.totalRemaining == 0" -->
              <button
                v-if="
                  checkPrivilege(
                    usersEstablishmentPrivilegeData
                      .usersPrivilegeEmployeesEnquirySalariesPaid
                      .employeesEnquirySalariesPaidAdd
                  )
                "
                v-b-modal.EmployeesEnquirySalariesPaidAdd
                class="btn p-0"
                :title="$t('newPayment')"
                @click="newSalaryPayment(SalaryInquiry)"
              >
                <img src="@/assets/images/pay.svg" class="icon-lg" />
              </button>
            </td>
            <td>
              <router-link
                v-if="
                  checkPrivilege(
                    usersEstablishmentPrivilegeData
                      .usersPrivilegeEmployeesEnquirySalariesPaid
                      .employeesEnquirySalariesPaidView
                  )
                "
                :to="{
                  name: 'EmployeesEnquirySalariesPaids',
                  params: {
                    salaryInquiryData: SalaryInquiry,
                  },
                }"
                :title="$t('EmployeesEnquirySalariesPaids.modelName')"
              >
                <img src="@/assets/images/paids.svg" class="icon-lg" />
              </router-link>
            </td>
            <td>
              <button
                v-b-modal.SalaryInquiryInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setEmployeesSalary(SalaryInquiry)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td>
              <button
                v-if="
                  checkPrivilege(
                    usersEstablishmentPrivilegeData
                      .usersPrivilegeEmployeesEnquirySalaries
                      .employeesEnquirySalariesDelete
                  )
                "
                v-b-modal.SalaryInquiryCancel
                class="btn p-0"
                :title="$t('cancel')"
                @click="setEmployeesSalary(SalaryInquiry)"
              >
                <img src="@/assets/images/cancel.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import DEFAULT_IMG from "@/assets/images/user.jpg";
import { BASE_URL } from "../../../utils/constants";
import { isDataExist, moneyFormat } from "../../../utils/functions";
import privilegeMixin from "@/utils/privilege-mixin";

export default {
  mixins: [privilegeMixin],
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      DEFAULT_IMG,
      BASE_URL,
    };
  },
  props: ["employeesSalaryData", "pagination"],
  methods: {
    newSalaryPayment(SalaryInquiry) {
      var data = {
        enquirySalarieToken: SalaryInquiry.enquirySalarieToken,
        enquirySalariePaidValue: SalaryInquiry.totalRemaining,
      };
      this.$emit("newSalaryPayment", data);
    },
    setEmployeesSalary(data) {
      this.$emit("setEmployeesSalary", data);
    },
    isDataExist,
    moneyFormat,
  },
  computed: {},
  async created() {},
};
</script>
