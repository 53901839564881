import WorkTimesDetails from "./WorkTimesDetails";
import axios from "axios";

export default class EmployeesSalary {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.year = "";
    this.month = "";
    this.enquirySalarieToken = "";
    this.employeeToken = "";
    this.employeeNameCurrent = "";
    this.employeeCode = "";
    this.employeeEmail = "";
    this.employeePhone = "";
    this.employeePhoneWithCC = "";
    this.employeeImagePath = "";
    this.totalSalary = "";
    this.totalInsuranceAmount = "";
    this.insuranceStatusNameCurrent = "";
    this.totalTaxAmount = "";
    this.taxStatusNameCurrent = "";
    this.employeeSalarySettingToken = "";
    this.totalIncreaseGrant = "";
    this.totalDutactionGrant = "";
    this.totalAllowancesMonth = "";
    this.totalKilometer = "";
    this.totalPoints = "";
    this.totalPriceKilometer = "";
    this.actionDateTime = "";
    this.enquirySalaryInquiryArchiveStatus = "";
    this.totalPaid = "";
    this.totalRemaining = "";
    this.totalWorkTimesPrice = "";
    this.workTimesDetails = new WorkTimesDetails();
    this.allemployeesStatus = false;
    this.enquirySalarieTokensList = [];
  }
  fillData(data) {
    this.year = data.year;
    this.month = data.month;
    this.enquirySalarieToken = data.enquirySalarieToken;
    this.employeeToken = data.employeeToken;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.employeeCode = data.employeeCode;
    this.employeeEmail = data.employeeEmail;
    this.employeePhone = data.employeePhone;
    this.employeePhoneWithCC = data.employeePhoneWithCC;
    this.employeeImagePath = data.employeeImagePath;
    this.totalSalary = data.totalSalary;
    this.totalInsuranceAmount = data.totalInsuranceAmount;
    this.insuranceStatusNameCurrent = data.insuranceStatusNameCurrent;
    this.totalTaxAmount = data.totalTaxAmount;
    this.taxStatusNameCurrent = data.taxStatusNameCurrent;
    this.employeeSalarySettingToken = data.employeeSalarySettingToken;
    this.totalIncreaseGrant = data.totalIncreaseGrant;
    this.totalDutactionGrant = data.totalDutactionGrant;
    this.totalAllowancesMonth = data.totalAllowancesMonth;
    this.totalKilometer = data.totalKilometer;
    this.totalPoints = data.totalPoints;
    this.totalPriceKilometer = data.totalPriceKilometer;
    this.actionDateTime = data.actionDateTime;
    this.enquirySalaryInquiryArchiveStatus =
      data.enquirySalaryInquiryArchiveStatus;
    this.totalPaid = data.totalPaid;
    this.totalRemaining = data.totalRemaining;
    this.totalWorkTimesPrice = data.totalWorkTimesPrice;
    this.workTimesDetails.fillData(data.workTimesDetails);
    this.allemployeesStatus = false;
    this.enquirySalarieTokensList = [];
  }
  async cancelSalaryInquiry(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      year: this.year,
      month: this.month,
      allemployeesStatus: false,
      enquirySalarieTokensList: this.enquirySalarieTokensList,
    };

    try {
      let response = await axios.post(
        `/EmployeesEnquirySalaries//FinalDeleteEmployeesEnquirySalaries`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
